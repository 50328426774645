import { Pipe, PipeTransform } from '@angular/core';
import { formatUTCToStringDate } from 'src/app/resources/shared-functions';

@Pipe({
  name: 'extractDateFromUTC'
})
export class ExtractDateFromUTC implements PipeTransform {

  transform(date: string): string {
    return formatUTCToStringDate(date);
  }
}
